#Reviews .bg-img-review {
    background-image: url('../images/img_reviews.jpg');
    background-position: 47% 3% !important;
}

.testimonial-box {
    padding: 8px;
}

.testimonial-card {
    border-radius: 25px;
    position: relative;
    padding: 30px;
    height: 100%;
    background-color: #fff;
    color: #212529;
}

.testimonial-colorized {
    background-color: #007EAF;
    color: #fff !important;
}

.testimonial-card .testimonial-img {
    width: 130px;
    height: 130px;
    object-fit: cover;
    position: relative;
}

.testimonial-icon {
    color: #FF9200;
}

.testimonial-colorized .testimonial-icon {
    color: #fff !important;
}

.bg-purple {
    background-color: #6610f2;
}

.testimonial-content {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

@media (max-width: 991px) {
    .testimonial-content {
        text-align: center;
    }

    .testimonial-content .box {
        justify-content: center;
    }
}

.show-more-less-clickable {
    color: #FF9200;
    text-decoration: none !important;
    font-weight: 600;
    transition: 0.3s ease;
}

.show-more-less-clickable:hover {
    color: #ac6202;
}

