.btn-cookie {
    color: #d5d5d5 !important;
    border-radius: 0.5em !important;
    transition: 0.3s !important;
    font-weight: 600 !important;
    background-color: #007EAF !important;
}

.btn-cookie:hover {
    background-color: #015f85 !important;
}

@media (max-width: 426px){
    .cookie-message {
        width: 100%;
        text-align: center;
    }
}
