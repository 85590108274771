.bg-img-about {
    background-image: url('../images/img_nosotros_1.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 45%;
}

.bg-img-about-2 {
    background-image: url('../images/img_nosotros_2.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 35%;
}

.bg-img-about-3 {
    background-image: url('../images/img_nosotros_3.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 45%;
}

.bg-shadow {
    background-color: rgba(0, 0, 0, 0.65) !important;
}

.about-title {
    font-weight: bold;
    font-size: 2.5rem !important;
    width: min-content;
}

.portfolio-title {
    font-weight: bold;
    font-size: 3rem !important;
    text-transform: capitalize;
    width: min-content;
}

@media (min-width: 1200px) {
    .portfolio-title {
        font-size: 5rem !important;
    }
    
    .about-title {
        font-size: 4rem !important;
    }
}

