@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

html,
body {
	height: 100% !important;
	font-family: 'Montserrat', sans-serif;
}

#root,
.App {
	height: 100% !important;
}

.text-justify {
	text-align: justify !important;
}

.rotate-prueba {
	transform: rotate(-90deg);
}

.bg-purple-ticongle {
	background-color: #241145 !important;
}

.bg-purple-dark-ticongle {
	background-color: #0D021E !important;
}

.h-25 {
	height: 25% !important;
}

.h-25-vh {
	height: 25vh !important;
}

.m-revert {
	margin: revert !important;
}

.language,
.social-text {
	list-style-type: none;
	padding: 0;
	font-weight: bold;
	font-size: 18px;
}

.language a {
	text-decoration: none !important;
	color: #fff !important;
}

.language a:hover {
	color: #d5d5d5 !important;
}

.social-text a {
	text-decoration: none !important;
	color: #fff !important;
	transition: 0.5s ease;
}

.social-text a:hover {
	color: #FF9200 !important;
}

.m-none {
	margin: 0 !important;
}

/*.border-red {
	border: 1px solid red !important;
}*/

/* Firefox */

* {
	scrollbar-width: thin;
	scrollbar-color: rgba(255, 146, 0) #0D021E;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
	width: 11px;
	height: 11px;
	transition: 0.5s ease;
}

::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

::-webkit-scrollbar-thumb {
	background: #ff9200;
	border: 0px none #ffffff;
	border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
	background: #d87c00;
}

::-webkit-scrollbar-thumb:active {
	background: #d87c00;
}

::-webkit-scrollbar-track {
	background: #0d021e;
	border: 0px none #ffffff;
	border-radius: 0px;
}

::-webkit-scrollbar-track:hover {
	background: #0d021e;
}

::-webkit-scrollbar-track:active {
	background: #0d021e;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

.body-content {
	padding-right: unset !important;
}

.body-scroll {
	overflow: auto;
}

.bg-transparent {
	background: transparent !important;
	border: none;
}

.st0 {
	opacity: 0.45;
	enable-background: new;
}

.st1 {
	opacity: 0.6;
	enable-background: new;
}

.st2 {
	opacity: 0.8;
	enable-background: new;
}

.ethereum-st0 {
	fill: #D6D6D6;
	stroke: #303030;
	stroke-linejoin: round;
}

.ethereum-st1 {
	fill: #C1C1C1;
	stroke: #303030;
	stroke-linejoin: round;
}

.ethereum-st2 {
	fill: #A8A8A8;
	stroke: #303030;
	stroke-linejoin: round;
}

.ethereum-st3 {
	fill: #E6E6E6;
	stroke: #303030;
	stroke-linejoin: round;
}

.btn-home {
	margin: 0 0 0 8px !important;
	color: #fff !important;
	text-decoration: none !important;
}


.btn-home:hover {
	color: #EC9118 !important;
}

.whatsapp-button {
	background-color: #25D366 !important;
	border: none !important;
	border-radius: 50% !important;
	width: 50px !important;
	height: 50px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	color: white !important;
	font-size: 24px !important;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
	transition: background-color 0.3s ease !important;
	position: fixed !important;
	bottom: 20px !important;
	right: 20px !important;
	z-index: 500 !important;
}

.whatsapp-button:hover {
	background-color: #1ebd56 !important;
}

.btns-container {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	gap: 20px !important;
	left: 0 !important;
	right: 0 !important;
	width: 100%;
}

.btn-main {
	display: flex !important;
	align-items: center !important;
	font-size: 22px !important;
	font-weight: bolder !important;
	color: #EC9118 !important;
	cursor: pointer !important;
	margin-top: 0 !important;
	border: none !important;
	background: none !important;
	text-decoration: none !important;
}

.icon {
	color: #EC9118 !important;
}

.question {
	display: flex !important;
	flex-direction: column;
	align-items: center !important;
	font-size: 25px !important;
	font-weight: bolder !important;
	color: #fff !important;
	cursor: pointer !important;
	border: none !important;
	background: none !important;
	text-decoration: none !important;
	margin: 0 0 0 8px !important;
	width: 100%;

}

.btn-service {
	margin: 0 0 0 8px !important;
	color: #fff !important;
	text-decoration: none !important;
	font-weight: bold !important;
	text-align: center !important;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	background: linear-gradient(45deg, #EC9118 10%, #92429B 60%);
	width: 250px;
	height: 50px;
}

.btn-service:hover {
	background: linear-gradient(45deg, #BF6E13 10%, #6D2C74 60%);
}


.question-service {
	display: flex !important;
	flex-direction: column;
	align-items: center !important;
	font-size: 25px !important;
	font-weight: bolder !important;
	color: #fff !important;
	cursor: pointer !important;
	background: none !important;
	text-decoration: none !important;
	margin: 45px 0 0 0 !important;
	width: 100%;
}

.review-btn {
	background-color: transparent;
	margin-top: 30px;
	align-items: center;
	justify-content: center;
	display: flex;
}

.init-btn {
	background-color: #080111 !important;
	padding-top: 55px !important;
	padding-bottom: 55px !important;
	align-items: center !important;
	justify-content: center !important;
	display: flex !important;
	width: 100%;
	margin-bottom: 0;
}

@media (max-width: 425px) {

	.question {
		font-size: 18px !important;
		text-align: center;
	}

	.btn-main {
		font-size: 16px !important;
	}

	.whatsapp-button {
		bottom: 20px !important;
		right: 20px !important;
		width: 40px !important;
		height: 40px !important;
	}

	.question-text {
		font-size: 20px !important;
	}

	.btn-service {
		font-size: 18px !important;
		width: 200px !important;
		height: 50px !important;
	}
}