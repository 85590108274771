.bg-img-about {
    background-image: url('../images/blog_1.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 45%;
}

.blog-loading {
    color: #fff !important;
}

.blog-content-section {
    background: #0D021E !important;
    background-clip: border-box !important;
    color: #fff !important;
    height: 475px;
    border-radius: 0.25rem !important;
    border: 1px solid #007EAF !important;
}

.blog-content-section .card-image-blog {
    overflow: hidden;
}

.blog-content-section .card-date {
    padding: 10px 1rem 0 1rem;
    color: #FF9200;
    font-weight: bold;
}

.blog-content-section .card-title {
    font-weight: 500;
}

.blog-content-section .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.blog-content-section .card-footer {
    background: #0D021E;
    border: none;
}

.blog-content-section .card-footer .btn-read-more {
    background: #FF9200 !important;
    color: #d5d5d5;
    border-radius: 0.5rem;
    font-weight: 600;
    transition: 0.1s ease;
}

.blog-content-section .card-footer .btn-read-more:hover {
    background: #ac6202 !important;
}

.not-post-blog {
    font-weight: bold;
}

.entry-title {
    color: #FF9200;
}

@keyframes pulse-animation {
    0% {
        /* transform: scale(1); */
        opacity: 1;
    }
    50% {
        /* transform: scale(1.05); */
        opacity: 0.5;
    }
    100% {
        /* transform: scale(1); */
        opacity: 1;
    }
}

.entry-title-load {
    background: #241145;
    width: 60%;
    animation: pulse-animation 2s infinite;
    border-radius: 0.5em !important;
}

.img-entry {
    border-radius: 0.5em !important;
}

.img-entry-load {
    background: #241145;
    height: 400px;
    animation: pulse-animation 2s infinite;
    border-radius: 0.5em !important;
}

.category-entry {
    margin-right: 0.5rem !important;
    font-size: 13px !important;
    background-color: #007EAF;
    border-radius: 0.5em !important;
}

.category-entry-load {
    margin-right: 0.5rem !important;
    width: 8%;
    background-color: #007EAF;
    animation: pulse-animation 2s infinite;
    border-radius: 0.5em !important;
}

.entry-date {
    background: #241145;
    width: 35%;
    border-radius: 0.5em !important;
    animation: pulse-animation 2s infinite;
}

#Entry .entry-content-load {
    background: #241145;
    height: 300px;
    width: 100%;
    border-radius: 0.5em !important;
    animation: pulse-animation 2s infinite;
}

#Entry .entry-content p {
    margin-bottom: 1.5rem !important;
    line-height: 1.5;
    font-size: 1rem;
}

#Entry .entry-content p img {
    max-width: 100%;
    height: auto;
}

#Entry .entry-content p a {
    text-decoration: none;
}

#Entry .entry-content p > a[title="Ebook"] {
    text-decoration: none;
    color: #fff !important;
    text-align: center;
    background: #007EAF;
    padding: 1rem 2rem;
    font-size: 1.175rem;
    font-weight: bold;
    border-radius: 0.5rem;
    transition: all 0.1s ease;
}

#Entry .entry-content p > a[title="Ebook"]:hover {
    background: #015f85;
}

#Entry .entry-content p a,
#Entry .entry-content p span a,
#Entry .entry-content p a * {
    color: #FF9200 !important;
    text-decoration: none;
    transition: 0.1s ease;
}

#Entry .entry-content p a:hover,
#Entry .entry-content p span a:hover,
#Entry .entry-content p a *:hover {
    color: #ac6202;
}

#Entry .entry-content h1 {
    margin-top: 3rem;
    margin-bottom: 1.5rem !important;
    font-weight: bolder;
}

#Entry .tiktok-embed .css-g03djc {
    background: #0D021E !important;
}

#Entry .tiktok-embed > section {
    background: #fff !important;
}
