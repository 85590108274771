.language-responsive a {
    text-decoration: none !important;
    font-weight: bold;
	font-size: 18px;
    color: #fff !important;
    transition: 0.5s !important;
}

.language-responsive a:hover {
    color: #d5d5d5 !important;
}

.nav-content {
    background-color: rgba(0, 0, 0, 0.80);
    color: #fff;
    height: 100vh;
    right: 0;
    position: fixed;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.7s;
}

.nav-mobile {
    font-size: 1.33rem;
    font-weight: bold;
}

.nav-mobile .item {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
    white-space: nowrap !important;
}

.nav-mobile .item .link {
    text-decoration: none !important;
    color: #fff;
    transition: 0.5s;
}

.nav-mobile .item .link:hover {
    color: #b1aeae;
}

.submenu-mobile {
    overflow: hidden;
}

.submenu-mobile .sub-item .link-subitem {
    text-decoration: none;
    color: #FF9200 !important;
    font-weight: 500 !important;
    padding-left: 0.5rem;
}

.submenu-mobile .sub-item .link-subitem:hover {
    color: #ac6202 !important;
}

.h-0-submenu-mobile {
    max-height: 0;
    transition: max-height 0.15s ease-out;
}

.h-auto-submenu-mobile {
    height: 260px;
    max-height: 500px;
    transition: max-height 0.25s ease-in;
}

