.portfolio-title {
    font-weight: bold;
    font-size: 3rem !important;
    text-transform: capitalize;
    width: min-content;
}

.description-title-section {
    font-weight: bold;
    font-size: 2rem !important;
}

.description-title {
    font-size: 1rem;
}

.capabilities-list {
    list-style-type: none;
}

.capabilities-list li {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem !important;
    font-weight: bold;
    color: #fff;
}

@media (min-width: 1200px) {
    .portfolio-title {
        font-size: 5rem !important;
    }

    .description-title-section {
        font-size: 3rem !important;
    }

    .description-title {
        font-size: 1.1rem;
    }

    .capabilities-list li {
        font-size: 1.3rem !important;
    }
}

.bg-service-portfolio {
    background-color: #0D021E !important;
}

.bg-shadow-service {
    background-color: rgba(0, 0, 0, 0.25) !important;
}

#portfolio .bg-config {
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.rec-pagination {
    display: none !important;
}

.img-project-portfolio {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.7s ease;
    backface-visibility: hidden;
}

.item-container {
    height: max-content !important;
    width: 100%;
    overflow: hidden;
    border: 3px solid;
    border-radius: 0.5em;
}

.item-container:hover {
    z-index: 5 !important;
}
  
.item-container:hover .img-project-portfolio {
    /* opacity: 0.5;  */
    transform: scale(1.2);
}
  
.item-container:hover .middle {
    opacity: 1;
}
 
.rec-carousel .rec-arrow-left,
.rec-carousel .rec-arrow-right {
    background-color: #FF9200 !important;
    color: #222 !important;
}

.rec-carousel .rec-arrow-left:hover,
.rec-carousel .rec-arrow-right:hover {
    background-color: #93439C !important;
    color: #fff !important;
}

/* Makerting digialt , Fotografía, Evetos online*/
#portfolio .bg-digital-marketing, 
#portfolio .bg-online-events, 
#portfolio .bg-photo {
    background-color: #EC9118 !important;
}

#portfolio .bg-img-digital-marketing {
    background-image: url('../images/img_marketing.jpg');
    background-position: center;
}

#portfolio .bg-img-description-digital-marketing {
    background-image: url('../images/img_marketing_2.jpg');
    background-position: 17%;
}

#portfolio .text-digital-marketing, 
#portfolio .text-online-events, 
#portfolio .text-photo {
    color: #EC9118 !important;
}

#portfolio .link-digital-marketing, 
#portfolio .link-online-events, 
#portfolio .link-photo {
    color: #fff !important;
}

#portfolio .link-digital-marketing:hover, 
#portfolio .link-photo:hover {
    color: #EC9118 !important;
}

#portfolio .border-color-digital-marketing, 
#portfolio .border-color-online-events, 
#portfolio .border-color-photo {
    color: #EC9118 !important;
    transition: 0.5s;
}

#portfolio .border-color-digital-marketing:hover,
#portfolio .border-color-online-events:hover,
#portfolio .border-color-photo:hover {
    color: #975d10 !important;
}

#portfolio .border-digital-marketing, 
#portfolio .border-online-events, 
#portfolio .border-photo {
    border-bottom: 3px solid #EC9118 !important
}

/* Desarrollo web, Producción audiovisual */

#portfolio .bg-web-development,
#portfolio .bg-audiovisual-production  {
    background-color: #047DAC !important;
}

#portfolio .bg-img-web-development {
    background-image: url('../images/img_desarrollador_web.jpg');
    background-position: left;
}

#portfolio .bg-img-description-web-development {
    background-image: url('../images/img_desarrollador_web_2.jpg');
    background-position: 30%;
}

#portfolio .text-web-development,
#portfolio .text-audiovisual-production {
    color: #047DAC !important;
}

#portfolio .link-web-development,
#portfolio .link-audiovisual-production {
    color: #fff !important;
}

#portfolio .link-web-development:hover,
#portfolio .link-audiovisual-production:hover {
    color: #047DAC !important;
}

#portfolio .border-color-web-development,
#portfolio .border-color-audiovisual-production {
    color: #047DAC !important;
    transition: 0.5s;
}

#portfolio .border-color-web-development:hover,
#portfolio .border-color-audiovisual-production:hover {
    color: #015272 !important;
}

#portfolio .border-web-development,
#portfolio .border-audiovisual-production {
    border-bottom: 3px solid #047DAC !important
}

/* App development */

#portfolio .bg-app-development,
#portfolio .bg-blockchain,
#portfolio .bg-branding {
    background-color: #814498 !important;
}

#portfolio .bg-img-app-development {
    background-image: url('../images/img_desarrollador_apps.jpg');
    background-position: center;
}

#portfolio .bg-img-description-app-development {
    background-image: url('../images/img_desarrollador_apps_2.jpg');
    background-position: 30%;
}

#portfolio .text-app-development,
#portfolio .text-blockchain,
#portfolio .text-branding {
    color: #814498 !important;
}

#portfolio .link-app-development,
#portfolio .link-blockchain,
#portfolio .link-branding {
    color: #fff !important;
}

#portfolio .link-app-development:hover,
#portfolio .link-blockchain:hover,
#portfolio .link-branding:hover {
    color: #814498 !important;
}

#portfolio .border-color-app-development,
#portfolio .border-blockchain,
#portfolio .border-color-branding {
    color: #814498 !important;
    transition: 0.5s;
}

#portfolio .border-color-app-development:hover,
#portfolio .border-color-blockchain:hover,
#portfolio .border-color-branding:hover {
    color: #5b276e !important;
}

#portfolio .border-app-development,
#portfolio .border-blockchain,
#portfolio .border-branding {
    border-bottom: 3px solid #814498 !important
}

/* Fotografia */

#portfolio .bg-img-photo {
    background-image: url('../images/img_fotografia.jpg');
    background-position: 35%;
}

#portfolio .bg-img-description-photo {
    background-image: url('../images/img_fotografia_2.jpg');
    background-position: center;
}

/* Producción audiovisual */

#portfolio .bg-img-audiovisual-production {
    background-image: url('../images/img_audiovisual.jpg');
    background-position: 65% !important;
}

#portfolio .bg-img-description-audiovisual-production {
    background-image: url('../images/img_audiovisual_2.jpg');
    background-position: 65% !important;
}

/* Branding */

#portfolio .bg-img-branding {
    background-image: url('../images/img_branding.jpg');
    background-position: 90% !important;
}

#portfolio .bg-img-description-branding {
    background-image: url('../images/img_branding_2.jpg');
    background-position: center;
}

/* Eventos online */

#portfolio .bg-img-online-events {
    background-image: url('../images/img_eventos_1.jpg');
    background-position: 35% !important;
}

#portfolio .bg-img-description-online-events {
    background-image: url('../images/img_eventos_2.jpg');
    background-position: 95% !important;
}

/* Blockchain */

#portfolio .bg-img-blockchain {
    background-image: url('../images/blockchain_3.jpg');
    background-position: center;
}

#portfolio .bg-img-description-blockchain {
    background-image: url('../images/blockchain_2.jpg');
    background-position: 30%;
}


[data-rmiz-wrap="visible"], 
[data-rmiz-wrap="hidden"] {
    display: flex !important;
}

#portfolio-details {
    height: auto !important;
}

@media (min-width: 992px) {
    #portfolio-details {
        height: 100vh !important;
    }   
}

.card-portfolio {
    background: transparent !important;
}

.btn-capability {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    color: #fff;
    font-weight: bold;
    transition: 0.3s ease;
}

.btn-capability:hover {
    color: #b3b1b1;
}

@media (max-width: 425px) {
    #web-plataforms .plataform .card-body {
        padding: 0 !important;
    }
}

#blockchain-plataforms .plataform .card-body {
    padding: 1rem !important;
}

@media (max-width: 425px) {
    #blockchain-plataforms .plataform .card-body {
        padding: 0 !important;
    }
}

#blockchain-techs .tech .card-body {
    padding: 1rem !important;
}

@media (max-width: 425px) {
    #blockchain-techs .tech .card-body {
        padding: 0 !important;
    }
}

.estilo_gráfico_lannich {
    fill: none;
}

.st0_lannich {
    fill: #FFFFFF;
}
.st1_lannich {
    fill: #525252;
}
.st2_lannich {
    fill: #555555;
}
.st3_lannich {
    fill: #D5D5D5;
}
.st4_lannich {
    fill: #595959;
}
.st5_lannich {
    fill: #BDBDBD;
}
.st6_lannich {
    fill: #707070;
}
.st7_lannich {
    fill: #6F6F6F;
}
.st8_lannich {
    fill: #DBDBDB;
}
.st9_lannich {
    fill: #DFDFDF;
}
.st10_lannich {
    fill: #8C8C8C;
}
.st11_lannich {
    fill: #A3A3A3;
}
.st12_lannich {
    fill: #C6C6C6;
}

