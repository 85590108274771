.bg-img-contact {
    background-image: url('../images/img_contacto_1.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 21%;
}

.bg-img-contact-2 {
    background-image: url('../images/img_contacto_2.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
}

.textarea-resize {
    resize: unset !important;
    height: 100px;
}

.form-about-title {
    font-weight: bold;
}

@media (min-width: 1200px) {
    .form-about-title {
        /* text-transform: capitalize; */
        font-size: 4rem !important;
    }
}

.contact-key-title {
    /* font-weight: 500 !important; */
    font-family: 'Caveat', cursive;
    /* font-style: italic; */
}

.contact-key .contact-key-name {
    font-weight: bold;
    font-size: 1.35rem;
    border-bottom: 1px solid #FF9200 !important;
}

.contact-key .contact-key-link {
    text-decoration: none;
    color: #fff;
    transition: 0.5s;
    width: auto !important;
    max-width: max-content !important;
}

.contact-key .contact-key-link:hover {
    color: #FF9200;
}

.contact-form .form-control-style {
    /* border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0; */
    border: 2px solid #92429B;
    background: transparent;
    transition: 0.3s;
    color: #fff !important;
}

.contact-form .form-control-style-textarea {
    /* border-radius: 0; */
    border: 2px solid #92429B;
    background: transparent;
    transition: 0.3s;
    color: #fff !important;
}

.contact-form .form-control-style:focus,
.contact-form .form-control-style-textarea:focus {
    color: #212529;
    background-color: transparent !important;
    border-color: #241145;
    outline: 0;
    box-shadow: none;
}

.form-control-style:-webkit-autofill,
.form-control-style:-webkit-autofill:hover,
.form-control-style:-webkit-autofill:focus,
.form-control-style:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.form-control-style:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
}

.contact-form button[type="submit"] {
    color: #fff !important;
    background-color: #FF9200;
    border-color: #FF9200;
    font-weight: 600;
    transition: 0.5s;
    border-radius: 0.6em;
}

.contact-form button[type="submit"]:hover {
    background-color: #ac6202;
    border-color: #ac6202;
}

.contact-form button[type="submit"]:focus {
    box-shadow: 0 0 0 0.25rem rgb(255 146 0 / 50%);
}

.icon-color {
    color: #FF9200;
    margin-right: 8px;
}