.ticongle-agency-brand {
    width: 100%;
    height: 100%;
}

.cursor-pointer {
    cursor: pointer !important;
}

.p-right-none {
    padding-right: revert !important;
}

.bg-sidebar-right {
    background-color: rgba(0, 0, 0, 0.80);
}

.sidebar-menu {
    position: fixed;
    z-index: 1;
    top: 0;
    left: auto;
    overflow-x: hidden;
    transition: 0.7s;
}

.w-0 {
    width: 0 !important;
}

.h-0 {
    height: 0px !important;
    max-height: 0px !important;
}

.sidebar-items {
    font-size: 24px;
    font-weight: bold;
}

.sidebar-items .item {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    white-space: nowrap !important;
}

.sidebar-items .item .link {
    text-decoration: none !important;
    color: #fff;
    transition: 0.5s;
}

.sidebar-items .item .link:hover {
    color: #b1aeae;
}

.pl-5-rem {
    padding-left: 5rem !important;
}

.w-23 {
    width: 23% !important;
}

.submenu-sidebar {
    overflow: hidden;
}

.submenu-sidebar .sub-item .link-subitem {
    text-decoration: none;
    color: #FF9200 !important;
    font-weight: 500 !important;
    padding-left: 0.5rem;
}

.submenu-sidebar .sub-item .link-subitem:hover {
    color: #ac6202 !important;
}

.h-0-submenu {
    max-height: 0;
    transition: max-height 0.15s ease-out;
}

.h-auto-submenu {
    height: 280px;
    max-height: 500px;
    transition: max-height 0.25s ease-out;
}

.visibility-hidden {
    visibility: hidden !important;
}

.language .translate-link,
.language-responsive .translate-link {
    color: #fff;
    cursor: pointer;
    transition:  0.5s ease;
    font-weight: bold;
}

.language .translate-link:hover,
.language-responsive .translate-link:hover {
    color: #FF9200;
}

.sidebar-padding {
    padding-top: 2rem;
    padding-bottom: 5rem;
}
