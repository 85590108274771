.card-team {
    background-color: #007EAF !important;
    /* border: 3px solid #FF9200 !important; */
    border: 0px !important;
}

#Team .slick-prev, 
#Team .slick-next,
#portfolio .slick-prev, 
#portfolio .slick-next {
    width: 50px;
    height: 50px;
    background-color: #FF9200 !important;
    border-radius: 50%;
    transition: 0.3s ease !important;
}

#Team .slick-prev:hover, 
#Team .slick-next:hover,
#portfolio .slick-prev:hover, 
#portfolio .slick-next:hover {
    background-color: rgba(103, 58, 183, 1) !important;
}

#Team .slick-prev:before,
#portfolio .slick-prev:before {
    font-family: 'FontAwesome' !important;
    content: "\f053";
    color: #222 !important;
    opacity: 1;
}

#Team .slick-next:before,
#portfolio .slick-next:before {
    font-family: 'FontAwesome' !important;
    content: "\f054";
    color: #222 !important;
    opacity: 1;
}

#Team .slick-prev:hover:before,
#Team .slick-next:hover:before,
#portfolio .slick-prev:hover:before,
#portfolio .slick-next:hover:before {
    color: #fff !important;
}

/* [1] The container */
.img-hover-zoom {
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
    transition: transform .9s ease;
    background-color: #000 !important;
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
    transition: transform .9s ease, opacity .25s ease-in-out;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
    transform: scale(1.3);
    opacity: 0.5;
}

#Team .overlay {
    transition: .9s ease;
    position: absolute;
    top: 0;
    bottom: 15%;
    left: 0;
    right: 0;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Team .img-hover-zoom:hover .overlay {
    opacity: 1;
}

#Team #mobile-team .slick-prev, 
#Team #mobile-team .slick-next,
#Team #desktop-team .slick-prev, 
#Team #desktop-team .slick-next,
#portfolio #mobile-portfolio .slick-prev, 
#portfolio #mobile-portfolio .slick-next,
#portfolio #desktop-portfolio .slick-prev, 
#portfolio #desktop-portfolio .slick-next,
#portfolio .slick-prev, 
#portfolio .slick-next {
    z-index: 1;
}

@media (max-width: 375px) {
    #Team #mobile-team .slick-prev, 
    #Team #mobile-team .slick-next,
    #portfolio #mobile-portfolio .slick-prev, 
    #portfolio #mobile-portfolio .slick-next {
        width: 30px;
        border-radius: 0.25rem;
    }
}

@media (max-width: 425px) {
    #Team #desktop-team .slick-slider,
    #portfolio #desktop-portfolio .slick-slider {
        padding: 0 !important;
        margin: 0 !important;
    }

    #Team #desktop-team .slick-prev, 
    #Team #desktop-team .slick-next,
    #portfolio #desktop-portfolio .slick-prev, 
    #portfolio #desktop-portfolio .slick-next {
        width: 30px;
        border-radius: 0.25rem;
    }
}

@media (max-width: 1024px) {
    #Team .card-body {
        padding: 1rem 3px !important;
    }

    #Team .card-body small {
        font-size: .805em !important;
    }
}

@media (max-width: 425px) {
    #Team .card-body small {
        font-size: .820em !important;
    }
}

@media (max-width: 393px) {
    #Team .card-body small {
        font-size: .830em !important;
    }
}

@media (max-width: 375px) {
    #Team .card-body small {
        font-size: .800em !important;
    }
}

#Team .bg-img-team {
    background-image: url('../images/nuestro_equipo.jpg');
    background-position: 47% 3% !important;
}

@media (max-width: 1024px) {
    #Team #desktop-team .team-name {
        font-size: 1rem;
    }
}

@media (max-width: 425px) {
    #Team #desktop-team .team-name {
        font-size: 1.25rem;
    }
}

@media (max-width: 425px) {
    #Team #mobile-team .team-name {
        font-size: 1.05rem;
    }
}

.team-name,
.team-job {
    color: #fff !important;
}




