.title-not-found {
    font-family: 'Caveat', cursive;
    font-weight: bold;
    color: #FF9200 !important;
}

.icon-404-size {
    width: 55vw;
}

@media (min-width: 1200px) {
    .icon-404-size {
        width: 25vw;
    }
}

