.bg-footer {
    background-color: #0D021E !important;
}

.link-icon {
    color: #fff !important;
    transition: 0.5s;
}

.link-icon:hover {
    color: #FF9200 !important;
}

.slogan-footer {
    font-size: 35px !important;
    font-family: 'Caveat', cursive;
    /* font-family: 'Great Vibes', cursive; */
}

.copyright .link-copyright-author {
    text-decoration: none !important;
    font-weight: bold;
    color: #FF9200 !important;
    transition: 0.5s;
}

.copyright .link-copyright-author:hover {
    color: #ac6202 !important;
}

