@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
.ticongle-agency-brand {
    width: 100%;
    height: 100%;
}

.cursor-pointer {
    cursor: pointer !important;
}

.p-right-none {
    padding-right: revert !important;
}

.bg-sidebar-right {
    background-color: rgba(0, 0, 0, 0.80);
}

.sidebar-menu {
    position: fixed;
    z-index: 1;
    top: 0;
    left: auto;
    overflow-x: hidden;
    transition: 0.7s;
}

.w-0 {
    width: 0 !important;
}

.h-0 {
    height: 0px !important;
    max-height: 0px !important;
}

.sidebar-items {
    font-size: 24px;
    font-weight: bold;
}

.sidebar-items .item {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    white-space: nowrap !important;
}

.sidebar-items .item .link {
    text-decoration: none !important;
    color: #fff;
    transition: 0.5s;
}

.sidebar-items .item .link:hover {
    color: #b1aeae;
}

.pl-5-rem {
    padding-left: 5rem !important;
}

.w-23 {
    width: 23% !important;
}

.submenu-sidebar {
    overflow: hidden;
}

.submenu-sidebar .sub-item .link-subitem {
    text-decoration: none;
    color: #FF9200 !important;
    font-weight: 500 !important;
    padding-left: 0.5rem;
}

.submenu-sidebar .sub-item .link-subitem:hover {
    color: #ac6202 !important;
}

.h-0-submenu {
    max-height: 0;
    transition: max-height 0.15s ease-out;
}

.h-auto-submenu {
    height: 280px;
    max-height: 500px;
    transition: max-height 0.25s ease-out;
}

.visibility-hidden {
    visibility: hidden !important;
}

.language .translate-link,
.language-responsive .translate-link {
    color: #fff;
    cursor: pointer;
    transition:  0.5s ease;
    font-weight: bold;
}

.language .translate-link:hover,
.language-responsive .translate-link:hover {
    color: #FF9200;
}

.sidebar-padding {
    padding-top: 2rem;
    padding-bottom: 5rem;
}

.language-responsive a {
    text-decoration: none !important;
    font-weight: bold;
	font-size: 18px;
    color: #fff !important;
    transition: 0.5s !important;
}

.language-responsive a:hover {
    color: #d5d5d5 !important;
}

.nav-content {
    background-color: rgba(0, 0, 0, 0.80);
    color: #fff;
    height: 100vh;
    right: 0;
    position: fixed;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.7s;
}

.nav-mobile {
    font-size: 1.33rem;
    font-weight: bold;
}

.nav-mobile .item {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
    white-space: nowrap !important;
}

.nav-mobile .item .link {
    text-decoration: none !important;
    color: #fff;
    transition: 0.5s;
}

.nav-mobile .item .link:hover {
    color: #b1aeae;
}

.submenu-mobile {
    overflow: hidden;
}

.submenu-mobile .sub-item .link-subitem {
    text-decoration: none;
    color: #FF9200 !important;
    font-weight: 500 !important;
    padding-left: 0.5rem;
}

.submenu-mobile .sub-item .link-subitem:hover {
    color: #ac6202 !important;
}

.h-0-submenu-mobile {
    max-height: 0;
    transition: max-height 0.15s ease-out;
}

.h-auto-submenu-mobile {
    height: 260px;
    max-height: 500px;
    transition: max-height 0.25s ease-in;
}


.bg-footer {
    background-color: #0D021E !important;
}

.link-icon {
    color: #fff !important;
    transition: 0.5s;
}

.link-icon:hover {
    color: #FF9200 !important;
}

.slogan-footer {
    font-size: 35px !important;
    font-family: 'Caveat', cursive;
    /* font-family: 'Great Vibes', cursive; */
}

.copyright .link-copyright-author {
    text-decoration: none !important;
    font-weight: bold;
    color: #FF9200 !important;
    transition: 0.5s;
}

.copyright .link-copyright-author:hover {
    color: #ac6202 !important;
}


.p-none {
    padding: 0 !important;
}

#home-video {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.home-video-shadow {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-shadow-section {
    background-color: rgba(0, 0, 0, 0.70) !important;
}

.bg-orange {
    background-color: rgb(236, 145, 24) !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.justify-content-flex-start {
    justify-content: flex-start !important;
}

.text-home {
    font-size: 17px;
    font-weight: 700;
    color: #fff;
    text-align: left;
}

.text-home-md {
    font-size: 22px !important;
    font-weight: 700;
}

.h-100-vh {
    height: 100vh !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
}

.title-section {
    font-weight: bold !important;
}

@media (min-width: 1200px) {
    .title-section {
        font-size: 3.5rem !important;
    }

    .text-home {
        font-size: 22px !important;
    }
}

.top-bar-subtitle-section {
    height: 5px !important;
    width: 130px !important;
    margin-bottom: 3px;
}

.text-section-service {
    font-size: 20px !important;
    font-weight: 500;
}

.link-portfolio-section {
    text-decoration: none;
    font-size: 20px !important;
    transition: 0.3s;
    font-weight: 500;
}

#Home .bg-config {
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* Marketing digital, Fotografía, Eventos online */

#Home .bg-config-digital-marketing {
    background-position: center !important;
}

#Home .bg-img-digital-marketing {
    background-image: url(https://ticongle.agency/static/media/img_marketing.ab6ec72f.jpg);
}

#Home .bg-web-development,
#Home .bg-app-development,
/* #Home .bg-digital-marketing, */
/* #Home .bg-online-events, */
/* #Home .bg-photo  */
#Home .bg-branding
{
    background-color: #EC9118 !important;
}

#Home .text-web-development,
#Home .text-app-development,
/* #Home .text-digital-marketing,
#Home .text-online-events,
#Home .text-photo, */
#Home .text-branding {
    color: #EC9118 !important;
}

#Home .link-web-development,
#Home .link-app-development,
/* #Home .link-digital-marketing,
#Home .link-online-events,
#Home .link-photo, */
#Home .link-branding {
    color: #fff !important;
}

#Home .link-web-development:hover,
#Home .link-app-development:hover,
/* #Home .link-digital-marketing:hover,
#Home .link-online-events:hover,
#Home .link-photo:hover, */
#Home .link-branding:hover {
    color: #EC9118 !important;
}

/* Desarrollo web */

#Home .bg-config-web-development {
    background-position: 17% !important;
}

#Home .bg-img-web-development {
    background-image: url(https://ticongle.agency/static/media/img_desarrollador_web.c6dd7b7c.jpg);
}

#Home .bg-blockchain,
/* #Home .bg-web-development, */
/* #Home .bg-audiovisual-production, */
#Home .bg-photo,
#Home .bg-online-events {
    background-color: #047DAC !important;
}

#Home .text-blockchain,
/* #Home .text-web-development, */
/* #Home .text-audiovisual-production, */
#Home .text-photo,
#Home .text-online-events {
    color: #047DAC !important;
}

#Home .link-blockchain,
/* #Home .link-web-development, */
/* #Home .link-audiovisual-production, */
#Home .link-photo,
#Home .link-online-events {
    color: #fff !important;
}

#Home .link-blockchain:hover,
/* #Home .link-web-development:hover, */
/* #Home .link-audiovisual-production:hover, */
#Home .link-photo:hover,
#Home .link-online-events:hover {
    color: #047DAC !important;
}

/* Desarrollo de aplicaciones */

#Home .bg-config-app-development {
    background-position: center !important;
}

#Home .bg-img-app-development {
    background-image: url(https://ticongle.agency/static/media/img_desarrollador_apps.ba20b7b1.jpg);
}

#Home .bg-digital-marketing,
/* #Home .bg-app-development, */
/* #Home .bg-blockchain, */
/* #Home .bg-branding, */
#Home .bg-audiovisual-production {
    background-color: #814498 !important;
}

#Home .text-digital-marketing,
/* #Home .text-app-development, */
/* #Home .text-blockchain, */
/* #Home .text-branding, */
#Home .text-audiovisual-production {
    color: #814498 !important;
}

#Home .link-digital-marketing,
/* #Home .link-app-development,
#Home .link-blockchain,
#Home .link-branding, */
#Home .link-audiovisual-production {
    color: #fff !important;
}

#Home .link-digital-marketing:hover,
/* #Home .link-app-development:hover,
#Home .link-blockchain:hover,
#Home .link-branding:hover, */
#Home .link-audiovisual-production:hover {
    color: #814498 !important;
}

/* Fotografia */

#Home .bg-config-photo {
    background-position: 35% !important;
}

#Home .bg-img-photo {
    background-image: url(https://ticongle.agency/static/media/img_fotografia.d628b7f2.jpg);
}

/* Producción audiovisual */

#Home .bg-config-audiovisual-production {
    background-position: 65% !important;
}

#Home .bg-img-audiovisual-production {
    background-image: url(https://ticongle.agency/static/media/img_audiovisual.fa1f3707.jpg);
}

/* Branding */

#Home .bg-config-branding {
    background-position: 90% !important;
}

#Home .bg-img-branding {
    background-image: url(https://ticongle.agency/static/media/img_branding.fc5335ca.jpg);
}

/* Eventos online */

#Home .bg-config-online-events {
    background-position: 35% !important;
}

#Home .bg-img-online-events {
    background-image: url(https://ticongle.agency/static/media/img_eventos_1.a5fa16b0.jpg);
}

/* Fotografia */

#Home .bg-config-blockchain {
    background-position: 35% !important;
}

#Home .bg-img-blockchain {
    background-image: url(https://ticongle.agency/static/media/blockchain_1.4f859952.jpg);
}

.card-tech .tech-icon {
    width: 65% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-tech .tech-body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    grid-gap: 10px;
    gap: 10px;
}

/* .card-tech .tech-body::before,
.card-tech .tech-body::after {
    content: '';
    flex: 1;
} */

.text-certification {
    color: #EC9118 !important;
}


.bg-img-about {
    background-image: url(https://ticongle.agency/static/media/img_nosotros_1.a23a55ef.jpg);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 45%;
}

.bg-img-about-2 {
    background-image: url(https://ticongle.agency/static/media/img_nosotros_2.748646f3.jpg);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 35%;
}

.bg-img-about-3 {
    background-image: url(https://ticongle.agency/static/media/img_nosotros_3.2aa28ceb.jpg);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 45%;
}

.bg-shadow {
    background-color: rgba(0, 0, 0, 0.65) !important;
}

.about-title {
    font-weight: bold;
    font-size: 2.5rem !important;
    width: -webkit-min-content;
    width: min-content;
}

.portfolio-title {
    font-weight: bold;
    font-size: 3rem !important;
    text-transform: capitalize;
    width: -webkit-min-content;
    width: min-content;
}

@media (min-width: 1200px) {
    .portfolio-title {
        font-size: 5rem !important;
    }
    
    .about-title {
        font-size: 4rem !important;
    }
}


.bg-img-contact {
    background-image: url(https://ticongle.agency/static/media/img_contacto_1.ded95eec.jpg);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 21%;
}

.bg-img-contact-2 {
    background-image: url(https://ticongle.agency/static/media/img_contacto_2.583cf5df.jpg);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
}

.textarea-resize {
    resize: unset !important;
    height: 100px;
}

.form-about-title {
    font-weight: bold;
}

@media (min-width: 1200px) {
    .form-about-title {
        /* text-transform: capitalize; */
        font-size: 4rem !important;
    }
}

.contact-key-title {
    /* font-weight: 500 !important; */
    font-family: 'Caveat', cursive;
    /* font-style: italic; */
}

.contact-key .contact-key-name {
    font-weight: bold;
    font-size: 1.35rem;
    border-bottom: 1px solid #FF9200 !important;
}

.contact-key .contact-key-link {
    text-decoration: none;
    color: #fff;
    transition: 0.5s;
    width: auto !important;
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
}

.contact-key .contact-key-link:hover {
    color: #FF9200;
}

.contact-form .form-control-style {
    /* border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0; */
    border: 2px solid #92429B;
    background: transparent;
    transition: 0.3s;
    color: #fff !important;
}

.contact-form .form-control-style-textarea {
    /* border-radius: 0; */
    border: 2px solid #92429B;
    background: transparent;
    transition: 0.3s;
    color: #fff !important;
}

.contact-form .form-control-style:focus,
.contact-form .form-control-style-textarea:focus {
    color: #212529;
    background-color: transparent !important;
    border-color: #241145;
    outline: 0;
    box-shadow: none;
}

.form-control-style:-webkit-autofill,
.form-control-style:-webkit-autofill:hover,
.form-control-style:-webkit-autofill:focus,
.form-control-style:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

.form-control-style:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
}

.contact-form button[type="submit"] {
    color: #fff !important;
    background-color: #FF9200;
    border-color: #FF9200;
    font-weight: 600;
    transition: 0.5s;
    border-radius: 0.6em;
}

.contact-form button[type="submit"]:hover {
    background-color: #ac6202;
    border-color: #ac6202;
}

.contact-form button[type="submit"]:focus {
    box-shadow: 0 0 0 0.25rem rgb(255 146 0 / 50%);
}

.icon-color {
    color: #FF9200;
    margin-right: 8px;
}
.bg-img-about {
    background-image: url(https://ticongle.agency/static/media/blog_1.8a5b3e78.jpg);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 45%;
}

.blog-loading {
    color: #fff !important;
}

.blog-content-section {
    background: #0D021E !important;
    background-clip: border-box !important;
    color: #fff !important;
    height: 475px;
    border-radius: 0.25rem !important;
    border: 1px solid #007EAF !important;
}

.blog-content-section .card-image-blog {
    overflow: hidden;
}

.blog-content-section .card-date {
    padding: 10px 1rem 0 1rem;
    color: #FF9200;
    font-weight: bold;
}

.blog-content-section .card-title {
    font-weight: 500;
}

.blog-content-section .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.blog-content-section .card-footer {
    background: #0D021E;
    border: none;
}

.blog-content-section .card-footer .btn-read-more {
    background: #FF9200 !important;
    color: #d5d5d5;
    border-radius: 0.5rem;
    font-weight: 600;
    transition: 0.1s ease;
}

.blog-content-section .card-footer .btn-read-more:hover {
    background: #ac6202 !important;
}

.not-post-blog {
    font-weight: bold;
}

.entry-title {
    color: #FF9200;
}

@keyframes pulse-animation {
    0% {
        /* transform: scale(1); */
        opacity: 1;
    }
    50% {
        /* transform: scale(1.05); */
        opacity: 0.5;
    }
    100% {
        /* transform: scale(1); */
        opacity: 1;
    }
}

.entry-title-load {
    background: #241145;
    width: 60%;
    animation: pulse-animation 2s infinite;
    border-radius: 0.5em !important;
}

.img-entry {
    border-radius: 0.5em !important;
}

.img-entry-load {
    background: #241145;
    height: 400px;
    animation: pulse-animation 2s infinite;
    border-radius: 0.5em !important;
}

.category-entry {
    margin-right: 0.5rem !important;
    font-size: 13px !important;
    background-color: #007EAF;
    border-radius: 0.5em !important;
}

.category-entry-load {
    margin-right: 0.5rem !important;
    width: 8%;
    background-color: #007EAF;
    animation: pulse-animation 2s infinite;
    border-radius: 0.5em !important;
}

.entry-date {
    background: #241145;
    width: 35%;
    border-radius: 0.5em !important;
    animation: pulse-animation 2s infinite;
}

#Entry .entry-content-load {
    background: #241145;
    height: 300px;
    width: 100%;
    border-radius: 0.5em !important;
    animation: pulse-animation 2s infinite;
}

#Entry .entry-content p {
    margin-bottom: 1.5rem !important;
    line-height: 1.5;
    font-size: 1rem;
}

#Entry .entry-content p img {
    max-width: 100%;
    height: auto;
}

#Entry .entry-content p a {
    text-decoration: none;
}

#Entry .entry-content p > a[title="Ebook"] {
    text-decoration: none;
    color: #fff !important;
    text-align: center;
    background: #007EAF;
    padding: 1rem 2rem;
    font-size: 1.175rem;
    font-weight: bold;
    border-radius: 0.5rem;
    transition: all 0.1s ease;
}

#Entry .entry-content p > a[title="Ebook"]:hover {
    background: #015f85;
}

#Entry .entry-content p a,
#Entry .entry-content p span a,
#Entry .entry-content p a * {
    color: #FF9200 !important;
    text-decoration: none;
    transition: 0.1s ease;
}

#Entry .entry-content p a:hover,
#Entry .entry-content p span a:hover,
#Entry .entry-content p a *:hover {
    color: #ac6202;
}

#Entry .entry-content h1 {
    margin-top: 3rem;
    margin-bottom: 1.5rem !important;
    font-weight: bolder;
}

#Entry .tiktok-embed .css-g03djc {
    background: #0D021E !important;
}

#Entry .tiktok-embed > section {
    background: #fff !important;
}

#Reviews .bg-img-review {
    background-image: url(https://ticongle.agency/static/media/img_reviews.ceae6810.jpg);
    background-position: 47% 3% !important;
}

.testimonial-box {
    padding: 8px;
}

.testimonial-card {
    border-radius: 25px;
    position: relative;
    padding: 30px;
    height: 100%;
    background-color: #fff;
    color: #212529;
}

.testimonial-colorized {
    background-color: #007EAF;
    color: #fff !important;
}

.testimonial-card .testimonial-img {
    width: 130px;
    height: 130px;
    object-fit: cover;
    position: relative;
}

.testimonial-icon {
    color: #FF9200;
}

.testimonial-colorized .testimonial-icon {
    color: #fff !important;
}

.bg-purple {
    background-color: #6610f2;
}

.testimonial-content {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

@media (max-width: 991px) {
    .testimonial-content {
        text-align: center;
    }

    .testimonial-content .box {
        justify-content: center;
    }
}

.show-more-less-clickable {
    color: #FF9200;
    text-decoration: none !important;
    font-weight: 600;
    transition: 0.3s ease;
}

.show-more-less-clickable:hover {
    color: #ac6202;
}


.card-team {
    background-color: #007EAF !important;
    /* border: 3px solid #FF9200 !important; */
    border: 0px !important;
}

#Team .slick-prev, 
#Team .slick-next,
#portfolio .slick-prev, 
#portfolio .slick-next {
    width: 50px;
    height: 50px;
    background-color: #FF9200 !important;
    border-radius: 50%;
    transition: 0.3s ease !important;
}

#Team .slick-prev:hover, 
#Team .slick-next:hover,
#portfolio .slick-prev:hover, 
#portfolio .slick-next:hover {
    background-color: rgba(103, 58, 183, 1) !important;
}

#Team .slick-prev:before,
#portfolio .slick-prev:before {
    font-family: 'FontAwesome' !important;
    content: "\f053";
    color: #222 !important;
    opacity: 1;
}

#Team .slick-next:before,
#portfolio .slick-next:before {
    font-family: 'FontAwesome' !important;
    content: "\f054";
    color: #222 !important;
    opacity: 1;
}

#Team .slick-prev:hover:before,
#Team .slick-next:hover:before,
#portfolio .slick-prev:hover:before,
#portfolio .slick-next:hover:before {
    color: #fff !important;
}

/* [1] The container */
.img-hover-zoom {
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
    transition: transform .9s ease;
    background-color: #000 !important;
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
    transition: transform .9s ease, opacity .25s ease-in-out;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
    transform: scale(1.3);
    opacity: 0.5;
}

#Team .overlay {
    transition: .9s ease;
    position: absolute;
    top: 0;
    bottom: 15%;
    left: 0;
    right: 0;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Team .img-hover-zoom:hover .overlay {
    opacity: 1;
}

#Team #mobile-team .slick-prev, 
#Team #mobile-team .slick-next,
#Team #desktop-team .slick-prev, 
#Team #desktop-team .slick-next,
#portfolio #mobile-portfolio .slick-prev, 
#portfolio #mobile-portfolio .slick-next,
#portfolio #desktop-portfolio .slick-prev, 
#portfolio #desktop-portfolio .slick-next,
#portfolio .slick-prev, 
#portfolio .slick-next {
    z-index: 1;
}

@media (max-width: 375px) {
    #Team #mobile-team .slick-prev, 
    #Team #mobile-team .slick-next,
    #portfolio #mobile-portfolio .slick-prev, 
    #portfolio #mobile-portfolio .slick-next {
        width: 30px;
        border-radius: 0.25rem;
    }
}

@media (max-width: 425px) {
    #Team #desktop-team .slick-slider,
    #portfolio #desktop-portfolio .slick-slider {
        padding: 0 !important;
        margin: 0 !important;
    }

    #Team #desktop-team .slick-prev, 
    #Team #desktop-team .slick-next,
    #portfolio #desktop-portfolio .slick-prev, 
    #portfolio #desktop-portfolio .slick-next {
        width: 30px;
        border-radius: 0.25rem;
    }
}

@media (max-width: 1024px) {
    #Team .card-body {
        padding: 1rem 3px !important;
    }

    #Team .card-body small {
        font-size: .805em !important;
    }
}

@media (max-width: 425px) {
    #Team .card-body small {
        font-size: .820em !important;
    }
}

@media (max-width: 393px) {
    #Team .card-body small {
        font-size: .830em !important;
    }
}

@media (max-width: 375px) {
    #Team .card-body small {
        font-size: .800em !important;
    }
}

#Team .bg-img-team {
    background-image: url(https://ticongle.agency/static/media/nuestro_equipo.6199197f.jpg);
    background-position: 47% 3% !important;
}

@media (max-width: 1024px) {
    #Team #desktop-team .team-name {
        font-size: 1rem;
    }
}

@media (max-width: 425px) {
    #Team #desktop-team .team-name {
        font-size: 1.25rem;
    }
}

@media (max-width: 425px) {
    #Team #mobile-team .team-name {
        font-size: 1.05rem;
    }
}

.team-name,
.team-job {
    color: #fff !important;
}





.portfolio-title {
    font-weight: bold;
    font-size: 3rem !important;
    text-transform: capitalize;
    width: -webkit-min-content;
    width: min-content;
}

.description-title-section {
    font-weight: bold;
    font-size: 2rem !important;
}

.description-title {
    font-size: 1rem;
}

.capabilities-list {
    list-style-type: none;
}

.capabilities-list li {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem !important;
    font-weight: bold;
    color: #fff;
}

@media (min-width: 1200px) {
    .portfolio-title {
        font-size: 5rem !important;
    }

    .description-title-section {
        font-size: 3rem !important;
    }

    .description-title {
        font-size: 1.1rem;
    }

    .capabilities-list li {
        font-size: 1.3rem !important;
    }
}

.bg-service-portfolio {
    background-color: #0D021E !important;
}

.bg-shadow-service {
    background-color: rgba(0, 0, 0, 0.25) !important;
}

#portfolio .bg-config {
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.rec-pagination {
    display: none !important;
}

.img-project-portfolio {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.7s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.item-container {
    height: -webkit-max-content !important;
    height: max-content !important;
    width: 100%;
    overflow: hidden;
    border: 3px solid;
    border-radius: 0.5em;
}

.item-container:hover {
    z-index: 5 !important;
}
  
.item-container:hover .img-project-portfolio {
    /* opacity: 0.5;  */
    transform: scale(1.2);
}
  
.item-container:hover .middle {
    opacity: 1;
}
 
.rec-carousel .rec-arrow-left,
.rec-carousel .rec-arrow-right {
    background-color: #FF9200 !important;
    color: #222 !important;
}

.rec-carousel .rec-arrow-left:hover,
.rec-carousel .rec-arrow-right:hover {
    background-color: #93439C !important;
    color: #fff !important;
}

/* Makerting digialt , Fotografía, Evetos online*/
#portfolio .bg-digital-marketing, 
#portfolio .bg-online-events, 
#portfolio .bg-photo {
    background-color: #EC9118 !important;
}

#portfolio .bg-img-digital-marketing {
    background-image: url(https://ticongle.agency/static/media/img_marketing.ab6ec72f.jpg);
    background-position: center;
}

#portfolio .bg-img-description-digital-marketing {
    background-image: url(https://ticongle.agency/static/media/img_marketing_2.0092bc90.jpg);
    background-position: 17%;
}

#portfolio .text-digital-marketing, 
#portfolio .text-online-events, 
#portfolio .text-photo {
    color: #EC9118 !important;
}

#portfolio .link-digital-marketing, 
#portfolio .link-online-events, 
#portfolio .link-photo {
    color: #fff !important;
}

#portfolio .link-digital-marketing:hover, 
#portfolio .link-photo:hover {
    color: #EC9118 !important;
}

#portfolio .border-color-digital-marketing, 
#portfolio .border-color-online-events, 
#portfolio .border-color-photo {
    color: #EC9118 !important;
    transition: 0.5s;
}

#portfolio .border-color-digital-marketing:hover,
#portfolio .border-color-online-events:hover,
#portfolio .border-color-photo:hover {
    color: #975d10 !important;
}

#portfolio .border-digital-marketing, 
#portfolio .border-online-events, 
#portfolio .border-photo {
    border-bottom: 3px solid #EC9118 !important
}

/* Desarrollo web, Producción audiovisual */

#portfolio .bg-web-development,
#portfolio .bg-audiovisual-production  {
    background-color: #047DAC !important;
}

#portfolio .bg-img-web-development {
    background-image: url(https://ticongle.agency/static/media/img_desarrollador_web.c6dd7b7c.jpg);
    background-position: left;
}

#portfolio .bg-img-description-web-development {
    background-image: url(https://ticongle.agency/static/media/img_desarrollador_web_2.b4ed834b.jpg);
    background-position: 30%;
}

#portfolio .text-web-development,
#portfolio .text-audiovisual-production {
    color: #047DAC !important;
}

#portfolio .link-web-development,
#portfolio .link-audiovisual-production {
    color: #fff !important;
}

#portfolio .link-web-development:hover,
#portfolio .link-audiovisual-production:hover {
    color: #047DAC !important;
}

#portfolio .border-color-web-development,
#portfolio .border-color-audiovisual-production {
    color: #047DAC !important;
    transition: 0.5s;
}

#portfolio .border-color-web-development:hover,
#portfolio .border-color-audiovisual-production:hover {
    color: #015272 !important;
}

#portfolio .border-web-development,
#portfolio .border-audiovisual-production {
    border-bottom: 3px solid #047DAC !important
}

/* App development */

#portfolio .bg-app-development,
#portfolio .bg-blockchain,
#portfolio .bg-branding {
    background-color: #814498 !important;
}

#portfolio .bg-img-app-development {
    background-image: url(https://ticongle.agency/static/media/img_desarrollador_apps.ba20b7b1.jpg);
    background-position: center;
}

#portfolio .bg-img-description-app-development {
    background-image: url(https://ticongle.agency/static/media/img_desarrollador_apps_2.fb9bc424.jpg);
    background-position: 30%;
}

#portfolio .text-app-development,
#portfolio .text-blockchain,
#portfolio .text-branding {
    color: #814498 !important;
}

#portfolio .link-app-development,
#portfolio .link-blockchain,
#portfolio .link-branding {
    color: #fff !important;
}

#portfolio .link-app-development:hover,
#portfolio .link-blockchain:hover,
#portfolio .link-branding:hover {
    color: #814498 !important;
}

#portfolio .border-color-app-development,
#portfolio .border-blockchain,
#portfolio .border-color-branding {
    color: #814498 !important;
    transition: 0.5s;
}

#portfolio .border-color-app-development:hover,
#portfolio .border-color-blockchain:hover,
#portfolio .border-color-branding:hover {
    color: #5b276e !important;
}

#portfolio .border-app-development,
#portfolio .border-blockchain,
#portfolio .border-branding {
    border-bottom: 3px solid #814498 !important
}

/* Fotografia */

#portfolio .bg-img-photo {
    background-image: url(https://ticongle.agency/static/media/img_fotografia.d628b7f2.jpg);
    background-position: 35%;
}

#portfolio .bg-img-description-photo {
    background-image: url(https://ticongle.agency/static/media/img_fotografia_2.360b81ba.jpg);
    background-position: center;
}

/* Producción audiovisual */

#portfolio .bg-img-audiovisual-production {
    background-image: url(https://ticongle.agency/static/media/img_audiovisual.fa1f3707.jpg);
    background-position: 65% !important;
}

#portfolio .bg-img-description-audiovisual-production {
    background-image: url(https://ticongle.agency/static/media/img_audiovisual_2.30a595ca.jpg);
    background-position: 65% !important;
}

/* Branding */

#portfolio .bg-img-branding {
    background-image: url(https://ticongle.agency/static/media/img_branding.fc5335ca.jpg);
    background-position: 90% !important;
}

#portfolio .bg-img-description-branding {
    background-image: url(https://ticongle.agency/static/media/img_branding_2.65eca47b.jpg);
    background-position: center;
}

/* Eventos online */

#portfolio .bg-img-online-events {
    background-image: url(https://ticongle.agency/static/media/img_eventos_1.a5fa16b0.jpg);
    background-position: 35% !important;
}

#portfolio .bg-img-description-online-events {
    background-image: url(https://ticongle.agency/static/media/img_eventos_2.502de24d.jpg);
    background-position: 95% !important;
}

/* Blockchain */

#portfolio .bg-img-blockchain {
    background-image: url(https://ticongle.agency/static/media/blockchain_3.0a1888e2.jpg);
    background-position: center;
}

#portfolio .bg-img-description-blockchain {
    background-image: url(https://ticongle.agency/static/media/blockchain_2.9850713d.jpg);
    background-position: 30%;
}


[data-rmiz-wrap="visible"], 
[data-rmiz-wrap="hidden"] {
    display: flex !important;
}

#portfolio-details {
    height: auto !important;
}

@media (min-width: 992px) {
    #portfolio-details {
        height: 100vh !important;
    }   
}

.card-portfolio {
    background: transparent !important;
}

.btn-capability {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    color: #fff;
    font-weight: bold;
    transition: 0.3s ease;
}

.btn-capability:hover {
    color: #b3b1b1;
}

@media (max-width: 425px) {
    #web-plataforms .plataform .card-body {
        padding: 0 !important;
    }
}

#blockchain-plataforms .plataform .card-body {
    padding: 1rem !important;
}

@media (max-width: 425px) {
    #blockchain-plataforms .plataform .card-body {
        padding: 0 !important;
    }
}

#blockchain-techs .tech .card-body {
    padding: 1rem !important;
}

@media (max-width: 425px) {
    #blockchain-techs .tech .card-body {
        padding: 0 !important;
    }
}

.estilo_gráfico_lannich {
    fill: none;
}

.st0_lannich {
    fill: #FFFFFF;
}
.st1_lannich {
    fill: #525252;
}
.st2_lannich {
    fill: #555555;
}
.st3_lannich {
    fill: #D5D5D5;
}
.st4_lannich {
    fill: #595959;
}
.st5_lannich {
    fill: #BDBDBD;
}
.st6_lannich {
    fill: #707070;
}
.st7_lannich {
    fill: #6F6F6F;
}
.st8_lannich {
    fill: #DBDBDB;
}
.st9_lannich {
    fill: #DFDFDF;
}
.st10_lannich {
    fill: #8C8C8C;
}
.st11_lannich {
    fill: #A3A3A3;
}
.st12_lannich {
    fill: #C6C6C6;
}


.title-not-found {
    font-family: 'Caveat', cursive;
    font-weight: bold;
    color: #FF9200 !important;
}

.icon-404-size {
    width: 55vw;
}

@media (min-width: 1200px) {
    .icon-404-size {
        width: 25vw;
    }
}


.btn-cookie {
    color: #d5d5d5 !important;
    border-radius: 0.5em !important;
    transition: 0.3s !important;
    font-weight: 600 !important;
    background-color: #007EAF !important;
}

.btn-cookie:hover {
    background-color: #015f85 !important;
}

@media (max-width: 426px){
    .cookie-message {
        width: 100%;
        text-align: center;
    }
}

html,
body {
	height: 100% !important;
	font-family: 'Montserrat', sans-serif;
}

#root,
.App {
	height: 100% !important;
}

.text-justify {
	text-align: justify !important;
}

.rotate-prueba {
	transform: rotate(-90deg);
}

.bg-purple-ticongle {
	background-color: #241145 !important;
}

.bg-purple-dark-ticongle {
	background-color: #0D021E !important;
}

.h-25 {
	height: 25% !important;
}

.h-25-vh {
	height: 25vh !important;
}

.m-revert {
	margin: revert !important;
}

.language,
.social-text {
	list-style-type: none;
	padding: 0;
	font-weight: bold;
	font-size: 18px;
}

.language a {
	text-decoration: none !important;
	color: #fff !important;
}

.language a:hover {
	color: #d5d5d5 !important;
}

.social-text a {
	text-decoration: none !important;
	color: #fff !important;
	transition: 0.5s ease;
}

.social-text a:hover {
	color: #FF9200 !important;
}

.m-none {
	margin: 0 !important;
}

/*.border-red {
	border: 1px solid red !important;
}*/

/* Firefox */

* {
	scrollbar-width: thin;
	scrollbar-color: rgba(255, 146, 0) #0D021E;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
	width: 11px;
	height: 11px;
	-webkit-transition: 0.5s ease;
	transition: 0.5s ease;
}

::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

::-webkit-scrollbar-thumb {
	background: #ff9200;
	border: 0px none #ffffff;
	border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
	background: #d87c00;
}

::-webkit-scrollbar-thumb:active {
	background: #d87c00;
}

::-webkit-scrollbar-track {
	background: #0d021e;
	border: 0px none #ffffff;
	border-radius: 0px;
}

::-webkit-scrollbar-track:hover {
	background: #0d021e;
}

::-webkit-scrollbar-track:active {
	background: #0d021e;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

.body-content {
	padding-right: unset !important;
}

.body-scroll {
	overflow: auto;
}

.bg-transparent {
	background: transparent !important;
	border: none;
}

.st0 {
	opacity: 0.45;
	enable-background: new;
}

.st1 {
	opacity: 0.6;
	enable-background: new;
}

.st2 {
	opacity: 0.8;
	enable-background: new;
}

.ethereum-st0 {
	fill: #D6D6D6;
	stroke: #303030;
	stroke-linejoin: round;
}

.ethereum-st1 {
	fill: #C1C1C1;
	stroke: #303030;
	stroke-linejoin: round;
}

.ethereum-st2 {
	fill: #A8A8A8;
	stroke: #303030;
	stroke-linejoin: round;
}

.ethereum-st3 {
	fill: #E6E6E6;
	stroke: #303030;
	stroke-linejoin: round;
}

.btn-home {
	margin: 0 0 0 8px !important;
	color: #fff !important;
	text-decoration: none !important;
}


.btn-home:hover {
	color: #EC9118 !important;
}

.whatsapp-button {
	background-color: #25D366 !important;
	border: none !important;
	border-radius: 50% !important;
	width: 50px !important;
	height: 50px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	color: white !important;
	font-size: 24px !important;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
	transition: background-color 0.3s ease !important;
	position: fixed !important;
	bottom: 20px !important;
	right: 20px !important;
	z-index: 500 !important;
}

.whatsapp-button:hover {
	background-color: #1ebd56 !important;
}

.btns-container {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	grid-gap: 20px !important;
	gap: 20px !important;
	left: 0 !important;
	right: 0 !important;
	width: 100%;
}

.btn-main {
	display: flex !important;
	align-items: center !important;
	font-size: 22px !important;
	font-weight: bolder !important;
	color: #EC9118 !important;
	cursor: pointer !important;
	margin-top: 0 !important;
	border: none !important;
	background: none !important;
	text-decoration: none !important;
}

.icon {
	color: #EC9118 !important;
}

.question {
	display: flex !important;
	flex-direction: column;
	align-items: center !important;
	font-size: 25px !important;
	font-weight: bolder !important;
	color: #fff !important;
	cursor: pointer !important;
	border: none !important;
	background: none !important;
	text-decoration: none !important;
	margin: 0 0 0 8px !important;
	width: 100%;

}

.btn-service {
	margin: 0 0 0 8px !important;
	color: #fff !important;
	text-decoration: none !important;
	font-weight: bold !important;
	text-align: center !important;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	background: linear-gradient(45deg, #EC9118 10%, #92429B 60%);
	width: 250px;
	height: 50px;
}

.btn-service:hover {
	background: linear-gradient(45deg, #BF6E13 10%, #6D2C74 60%);
}


.question-service {
	display: flex !important;
	flex-direction: column;
	align-items: center !important;
	font-size: 25px !important;
	font-weight: bolder !important;
	color: #fff !important;
	cursor: pointer !important;
	background: none !important;
	text-decoration: none !important;
	margin: 45px 0 0 0 !important;
	width: 100%;
}

.review-btn {
	background-color: transparent;
	margin-top: 30px;
	align-items: center;
	justify-content: center;
	display: flex;
}

.init-btn {
	background-color: #080111 !important;
	padding-top: 55px !important;
	padding-bottom: 55px !important;
	align-items: center !important;
	justify-content: center !important;
	display: flex !important;
	width: 100%;
	margin-bottom: 0;
}

@media (max-width: 425px) {

	.question {
		font-size: 18px !important;
		text-align: center;
	}

	.btn-main {
		font-size: 16px !important;
	}

	.whatsapp-button {
		bottom: 20px !important;
		right: 20px !important;
		width: 40px !important;
		height: 40px !important;
	}

	.question-text {
		font-size: 20px !important;
	}

	.btn-service {
		font-size: 18px !important;
		width: 200px !important;
		height: 50px !important;
	}
}
