.p-none {
    padding: 0 !important;
}

#home-video {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.home-video-shadow {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-shadow-section {
    background-color: rgba(0, 0, 0, 0.70) !important;
}

.bg-orange {
    background-color: rgb(236, 145, 24) !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.justify-content-flex-start {
    justify-content: flex-start !important;
}

.text-home {
    font-size: 17px;
    font-weight: 700;
    color: #fff;
    text-align: left;
}

.text-home-md {
    font-size: 22px !important;
    font-weight: 700;
}

.h-100-vh {
    height: 100vh !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
}

.title-section {
    font-weight: bold !important;
}

@media (min-width: 1200px) {
    .title-section {
        font-size: 3.5rem !important;
    }

    .text-home {
        font-size: 22px !important;
    }
}

.top-bar-subtitle-section {
    height: 5px !important;
    width: 130px !important;
    margin-bottom: 3px;
}

.text-section-service {
    font-size: 20px !important;
    font-weight: 500;
}

.link-portfolio-section {
    text-decoration: none;
    font-size: 20px !important;
    transition: 0.3s;
    font-weight: 500;
}

#Home .bg-config {
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* Marketing digital, Fotografía, Eventos online */

#Home .bg-config-digital-marketing {
    background-position: center !important;
}

#Home .bg-img-digital-marketing {
    background-image: url('../images/img_marketing.jpg');
}

#Home .bg-web-development,
#Home .bg-app-development,
/* #Home .bg-digital-marketing, */
/* #Home .bg-online-events, */
/* #Home .bg-photo  */
#Home .bg-branding
{
    background-color: #EC9118 !important;
}

#Home .text-web-development,
#Home .text-app-development,
/* #Home .text-digital-marketing,
#Home .text-online-events,
#Home .text-photo, */
#Home .text-branding {
    color: #EC9118 !important;
}

#Home .link-web-development,
#Home .link-app-development,
/* #Home .link-digital-marketing,
#Home .link-online-events,
#Home .link-photo, */
#Home .link-branding {
    color: #fff !important;
}

#Home .link-web-development:hover,
#Home .link-app-development:hover,
/* #Home .link-digital-marketing:hover,
#Home .link-online-events:hover,
#Home .link-photo:hover, */
#Home .link-branding:hover {
    color: #EC9118 !important;
}

/* Desarrollo web */

#Home .bg-config-web-development {
    background-position: 17% !important;
}

#Home .bg-img-web-development {
    background-image: url('../images/img_desarrollador_web.jpg');
}

#Home .bg-blockchain,
/* #Home .bg-web-development, */
/* #Home .bg-audiovisual-production, */
#Home .bg-photo,
#Home .bg-online-events {
    background-color: #047DAC !important;
}

#Home .text-blockchain,
/* #Home .text-web-development, */
/* #Home .text-audiovisual-production, */
#Home .text-photo,
#Home .text-online-events {
    color: #047DAC !important;
}

#Home .link-blockchain,
/* #Home .link-web-development, */
/* #Home .link-audiovisual-production, */
#Home .link-photo,
#Home .link-online-events {
    color: #fff !important;
}

#Home .link-blockchain:hover,
/* #Home .link-web-development:hover, */
/* #Home .link-audiovisual-production:hover, */
#Home .link-photo:hover,
#Home .link-online-events:hover {
    color: #047DAC !important;
}

/* Desarrollo de aplicaciones */

#Home .bg-config-app-development {
    background-position: center !important;
}

#Home .bg-img-app-development {
    background-image: url('../images/img_desarrollador_apps.jpg');
}

#Home .bg-digital-marketing,
/* #Home .bg-app-development, */
/* #Home .bg-blockchain, */
/* #Home .bg-branding, */
#Home .bg-audiovisual-production {
    background-color: #814498 !important;
}

#Home .text-digital-marketing,
/* #Home .text-app-development, */
/* #Home .text-blockchain, */
/* #Home .text-branding, */
#Home .text-audiovisual-production {
    color: #814498 !important;
}

#Home .link-digital-marketing,
/* #Home .link-app-development,
#Home .link-blockchain,
#Home .link-branding, */
#Home .link-audiovisual-production {
    color: #fff !important;
}

#Home .link-digital-marketing:hover,
/* #Home .link-app-development:hover,
#Home .link-blockchain:hover,
#Home .link-branding:hover, */
#Home .link-audiovisual-production:hover {
    color: #814498 !important;
}

/* Fotografia */

#Home .bg-config-photo {
    background-position: 35% !important;
}

#Home .bg-img-photo {
    background-image: url('../images/img_fotografia.jpg');
}

/* Producción audiovisual */

#Home .bg-config-audiovisual-production {
    background-position: 65% !important;
}

#Home .bg-img-audiovisual-production {
    background-image: url('../images/img_audiovisual.jpg');
}

/* Branding */

#Home .bg-config-branding {
    background-position: 90% !important;
}

#Home .bg-img-branding {
    background-image: url('../images/img_branding.jpg');
}

/* Eventos online */

#Home .bg-config-online-events {
    background-position: 35% !important;
}

#Home .bg-img-online-events {
    background-image: url('../images/img_eventos_1.jpg');
}

/* Fotografia */

#Home .bg-config-blockchain {
    background-position: 35% !important;
}

#Home .bg-img-blockchain {
    background-image: url('../images/blockchain_1.jpg');
}

.card-tech .tech-icon {
    width: 65% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-tech .tech-body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
}

/* .card-tech .tech-body::before,
.card-tech .tech-body::after {
    content: '';
    flex: 1;
} */

.text-certification {
    color: #EC9118 !important;
}

